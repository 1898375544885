import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Tooltip, Skeleton, notification, Button, Dropdown, Menu, Popconfirm } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { DownloadOutlined, FilterOutlined, PlusOutlined, UploadOutlined, EditOutlined, EyeOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchDivisions, selectDivisions, deleteDivision, selectTotal, selectPagination, selectLoading, filterDivisions, generateCsv } from "../divisionsSlice"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import { filterSiteSurveys } from "../../ancillaryServices/siteSurveys/siteSurveysSlice"
// import { clientDivisionCsvUrl } from "../../../lib/endpoints"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"
import Role from "../../userRoles/Role"

export default function DivisionTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    let { clientId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const divisions = useSelector(selectDivisions)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialDivisions()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialDivisions = () => {
        dispatch(fetchDivisions(pagination, nodePath.join(url, "")))
    }

    const handleFilterDivisions = (newPagination = null, newSort = null) => {
        return dispatch(
            filterDivisions({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }
    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteDivision, id);
    };
    const handleSortDivisionsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterDivisions(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchDivisions(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterDivisions(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortDivisionsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialDivisions()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const openNotification = () => {
        notification.open({
            message: "Divisions export started",
            duration: 0.8,
        })
    }

    const divisionsCsv = async () => {
        notification.open({
            message: "Divisions export started",
            duration: 0.8,
        })

        dispatch(generateCsv(clientId, filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Divisions</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Role action="create" model="facility">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                <Link to={`${url}/import`}>
                                    <PlusOutlined />
                                    Import
                                </Link>

                                <Button type="link" onClick={() => divisionsCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={divisions} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialDivisions}
                                        filterAction={filterDivisions}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("short_code")}
                                key="short_code"
                                dataIndex="short_code"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialDivisions}
                                        filterAction={filterDivisions}
                                        filterColumn={"short_code"}
                                        filterType={"short_code_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("short_code_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("code")}
                                key="code"
                                dataIndex="code"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialDivisions}
                                        filterAction={filterDivisions}
                                        filterColumn={"code"}
                                        filterType={"code_eq"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("code_eq")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("client")}
                                key="client_id"
                                dataIndex="client"
                                render={(client) => client?.name}
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialDivisions}
                                        filterAction={filterDivisions}
                                        filterColumn={"client_name"}
                                        filterType={"client_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("client_name_cont")}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="division">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={`${url}/${record.id}/edit`}>Edit Division</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Menu.Item key="2" icon={<EyeOutlined />}>
                                                        <Link to={`${url}/${record.id}/dashboard`}>View</Link>
                                                    </Menu.Item>

                                                    <Role action="destroy" model="division">
                                                        <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                            Delete Division
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
