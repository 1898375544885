import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification, Select, Switch, DatePicker, InputNumber } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import {
    fetchAccountingBatch,
    setAccountingBatch,
    selectAccountingBatch,
    submitAccountingBatch,
    selectLoading,
    fetchBatchGroupSelectOptions,
    selectBatchGroupSelectOptions,
} from "../accountingBatchesSlice"

import { requestNotification } from "../../../../lib/notifications"
import DateMask from "../../../forms/components/DateMask"

const { Option } = Select
const dateFormat = "MM/DD/YYYY"

export default function AccountingBatchForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const accountingBatch = useSelector(selectAccountingBatch)
    const batchGroupSelectOptions = useSelector(selectBatchGroupSelectOptions)
    const loading = useSelector(selectLoading)
    const { accountingBatchId, clientId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        if (accountingBatchId) {
            dispatch(fetchAccountingBatch(accountingBatchId))
        } else {
            dispatch(setAccountingBatch({}))
        }
    }, [dispatch, accountingBatchId])

    // When a AccountingBatch is set, we need to fetch its Client in order
    // to prepopulate the Client select with the current value
    useEffect(() => {
        form.resetFields()
        dispatch(fetchBatchGroupSelectOptions(clientId))
    }, [dispatch, form, loaded, accountingBatch]) // eslint-disable-line

    const submit = async (values) => {
        // There's something really weird with antd onClear and submitting. Values get set to undefined rather than whatever you set them to.
        const response =
            values.batch_group_id === undefined
                ? await dispatch(submitAccountingBatch({ ...values, client_id: clientId, batch_group_id: null }, accountingBatchId, clientId))
                : await dispatch(submitAccountingBatch({ ...values, client_id: clientId }, accountingBatchId, clientId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            {/* TODO: will need to handle new vs edit action */}

            <Link to={actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>

            <Form form={form} initialValues={{ ...accountingBatch, batch_date: moment(accountingBatch.batch_date || new Date()) }} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Batch Group" name="batch_group_id" rules={[{ required: false }]}>
                                        <Select placeholder={"Select Batch Group"} showArrow={true} allowClear={true}>
                                            {batchGroupSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Closed" name="closed" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Invoiced" name="invoiced" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Batch Code" name="batch_code" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Batch Date" name="batch_date" rules={[{ required: false }]}>
                                        <DatePicker format={dateFormat} style={{ width: "100%" }} allowClear={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Bills Total" name="bills_total" rules={[{ required: false }]}>
                                        <InputNumber disabled={true} precision={2} style={{width: '100%'}} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                {/* Might be deleting this */}
                                {/* <Col span={8}>
                                    <Form.Item label="Service Fee" name="service_fee" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col> */}

                                {/* <Col span={8}>
                                    <Form.Item label="Line Item Count" name="line_item_count" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col> */}

                                {/* <Col span={8}>
                                    <Form.Item label="Bill Total" name="bill_total" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col> */}
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
