import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationPin } from "@fortawesome/free-solid-svg-icons"
import { selectScopedByClient } from "../../stores/appSlice"
import { fetchBillableAccount, selectBillableAccount } from "./billableAccountsSlice"

export default function BillableAccountNav({ activeTab, scopedByBillableAccount = false }) {
    const dispatch = useDispatch()
    const { clientId, billableAccountId } = useParams()
    const billableAccount = useSelector(selectBillableAccount)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the billableAccount we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (billableAccountId) {
            dispatch(fetchBillableAccount(billableAccountId))
        }
    }, [dispatch, billableAccountId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (billableAccountId && scopedByBillableAccount) {
                return `/clients/${clientId}/billable_accounts/${billableAccountId}${newRoute}`
            } else {
                return `/clients/${clientId}/billable_accounts${newRoute}`
            }
        } else if (billableAccountId) {
            return `/billable_accounts/${billableAccountId}${newRoute}`
        }

        return `/billable_accounts${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedByBillableAccount ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Billable Accounts</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Billable Accounts
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>
                    <Link to={() => `/clients/${clientId}/divisions/${billableAccount.division_id}/dashboard`}>{billableAccount.division_name} > </Link>
                    <Link to={() => `/clients/${clientId}/facilities/${billableAccount.facility_id}/dashboard`}>{billableAccount.facility_name} > </Link>
                    {`${billableAccount.account_number}`}
                    <a style={{marginLeft: "5px"}}  href={`https://www.google.com/maps/search/?api=1&query=${billableAccount.street_address_1}, ${billableAccount.state}, ${billableAccount.zip}`} target="_blank">
                        <FontAwesomeIcon icon={faLocationPin} color="#fbb040" />
                    </a>
                </h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Dashboard
                           </button>
                       </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/documents")}>
                            <button type="link" className={`${activeTab === "documents" ? "selected" : ""}`}>
                                Documents
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/services")}>
                            <button type="link" className={`${activeTab === "services" ? "selected" : ""}`}>
                                Services
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/bills")}>
                            <button type="link" className={`${activeTab === "bills" ? "selected" : ""}`}>
                                Billable Account's bills
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Billable Accounts</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                Billable Accounts List
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
