import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Button, Tooltip, Skeleton, Popconfirm, notification } from "antd"
import {
    DownloadOutlined,
    PlusOutlined,
    UploadOutlined,
    EditOutlined,
    UserOutlined,
    FilePdfOutlined
} from "@ant-design/icons"
import moment from "moment"

// import { fetchVendorPayments, selectVendorPayments, deleteVendorPayment, selectTotal, selectPagination, selectLoading } from "../paymentBatchesSlice"
import {
    fetchVendorPayments,
    generateVendorPaymentPdf,
    selectVendorPayments,
    selectVendorPaymentsTotal,
    deleteVendorPayment,
    selectLoading,
    selectPagination,
    generatePaymentBatchForVendorsPdf,
    filterVendorPayments
} from "../paymentBatchesSlice"
import {formatCurrency} from "../../../../lib/utilities";
import ColumnHeaderTitle from "../../../../components/utilityComponents/table/ColumnHeaderTitle"

export default function VendorPaymentTable({ scopedByClient }) {
    const { url } = useRouteMatch()
    const { clientId, paymentBatchId } = useParams()
    const dispatch = useDispatch()
    const utilityPayments = useSelector(selectVendorPayments)
    const total = useSelector(selectVendorPaymentsTotal)
    // const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const pagination = useSelector(selectPagination)
    const [sort, setSort] = useState({
        column: "amount",
        direction: "asc",
    })
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            dispatch(fetchVendorPayments(paymentBatchId))
        }
        // eslint-disable-next-line
    }, [loaded, url, dispatch])

    // const handleTableChange = (newPagination, filters, sorter) => {
    //     dispatch(fetchVendorPayments(clientId, newPagination))
    // }

    const deleteRecord = async (id) => {
        const response = await dispatch(deleteVendorPayment(id))
        if (response.success) {
            notification.open({
                message: "Success",
                description: "Deleted Vendor Payment",
                duration: 0.8,
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was an error deleting the Vendor Payment",
                duration: 0.8,
            })
        }
    }
    
    const handleSortVendorPaymentsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterVendorPayments(null, newSort)
    }

    const handleFilterVendorPayments = (newPagination = null, newSort = null) => {
        return dispatch(
            filterVendorPayments({
                clientId: clientId,
                paymentBatchId: paymentBatchId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const renderColumnHeaderTitle = (title, columnClass='') => {
        return <ColumnHeaderTitle title={title} columnClass={columnClass} handleSortChange={handleSortVendorPaymentsChange} sort={sort} />
    }

    const generateInvoicePdf = async (accountingBatchId) => {
        notification.open({
            message: "Vendor payments PDF generation started",
            duration: 0.8,
        })

        dispatch(generateVendorPaymentPdf(accountingBatchId))
    }

    const generatePaymentBatchPdf = async (paymentBatchId) => {
        notification.open({
            message: "Payments Batch PDF generation started",
            duration: 0.8,
        })

        dispatch(generatePaymentBatchForVendorsPdf(paymentBatchId))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Vendor Payments</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                <a href="javascript:;" onClick={() => generatePaymentBatchPdf(paymentBatchId)}>
                                    <FilePdfOutlined style={{color: 'red'}}/>
                                    Voucher Batch (PDF)
                                </a>
                                <a href="javascript:;" onClick={() => console.log("Export")}>
                                    <DownloadOutlined/>
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={utilityPayments} rowKey={(r) => r.id}>
                            <Table.Column title="Transaction ID" key="id" dataIndex="id" render={(text, record, index) => `5-${text}`}/>
                            <Table.Column title="Vendor Name" key="vendor_name" dataIndex="vendor_name" />
                            <Table.Column title={renderColumnHeaderTitle('payment_date')} key="payment_date" dataIndex="payment_date" render={(text, record) => <>{moment(record.payment_date).format("MM/DD/YYYY")}</>} />
                            <Table.Column title="Payment ID" key="check_number" dataIndex="check_number" />
                            <Table.Column title={renderColumnHeaderTitle('amount', 'right_align_column')} key="amount" dataIndex="amount" align="right" render={(text, record, index) => formatCurrency(text)}/>
                            <Table.Column title="Payment Methods" key="payment_methods" dataIndex="payment_methods" />
                            <Table.Column
                                title=""
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <div className="row-actions" style={{ display: "inline-block", marginRight: "10px" }}>
                                            <Link to={{ pathname: `${url}/${record.id}/bills`, utility_company_name: record.utility_company_name }}>Bills</Link>
                                        </div>

                                        <div className="row-actions pl-2" style={{ display: "inline-block" }}>
                                            <Link to={`${url}/${record.id}/edit`}>
                                                {/* <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip> */}
                                                Edit
                                            </Link>
                                        </div>
                                        <div className="row-actions pl-2" style={{display: "inline-block"}}>
                                            <a href="javascript:;" onClick={() => generateInvoicePdf(record.id)}>
                                                <FilePdfOutlined style={{color: 'red'}} />
                                                (PDF)
                                            </a>
                                        </div>
                                        <Popconfirm
                                          title="Are you sure you want to delete this Vendor Payment?"
                                          onConfirm={() => deleteRecord(record.id)}
                                            className="row-actions pl-2"
                                            disabled={record.locked}>
                                            <Link to={"javascript:;"} className={`${record.locked ? "disabled" : ""}`}>
                                                Delete
                                            </Link>
                                        </Popconfirm>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
