import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../lib/client"
import {
    // servicesUrl,
    serviceUrl,
    // clientServicesUrl,
    billableAccountServiceUrl,
    billableAccountServicesUrl,
    supplyAccountServiceUrl,
    supplyAccountServicesUrl,
    serviceOptionsForBillableAccount,
    serviceOptionsForSupplyAccount,
    entryFieldSelectOptionsUrl,
    lastFiveBillsForServiceUrl,
    dynamicUrl,
} from "../../lib/endpoints"

export const servicesSlice = createSlice({
    name: "services",
    initialState: {
        service: {},
        services: [],
        serviceSelectOptions: [],
        serviceOptionsForBillableAccount: [],
        serviceOptionsForSupplyAccount: [],
        entryFieldSelectOptions: [],
        serviceSearch: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setService: (state, action) => {
            state.service = action.payload
        },
        setServices: (state, action) => {
            state.services = action.payload.services
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setServiceSelectOptions: (state, action) => {
            state.serviceSelectOptions = action.payload
        },
        setServiceSearch: (state, action) => {
            state.serviceSearch = action.payload
        },
        setServiceOptionsForBillableAccount: (state, action) => {
            state.serviceOptionsForBillableAccount = action.payload
        },
        setServiceOptionsForSupplyAccount: (state, action) => {
            state.serviceOptionsForSupplyAccount = action.payload
        },
        setEntryFieldSelectOptions: (state, action) => {
            state.entryFieldSelectOptions = action.payload
        },
        deleteService: (state, action) => {
            state.services = state.services.filter((service) => service.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const {
    setService,
    setServices,
    setPagination,
    setLoading,
    setServiceSelectOptions,
    setServiceSearch,
    setServiceOptionsForBillableAccount,
    setServiceOptionsForSupplyAccount,
    setEntryFieldSelectOptions,
} = servicesSlice.actions

export function fetchService(id) {
    return async (dispatch, getState) => {
        client.get(serviceUrl(id)).then((response) => {
            dispatch(setService(response.data))
        })
    }
}

export function fetchServices(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        // This route may be nested so we have to use a dynamic route
        // /services VS /clients/client_id/services
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setServices(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientServices(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientServicesPath = `clients/${clientId}/services/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientServicesPath)}`)
        return response.data.services
    }
}

export function fetchLastFiveBillsForService(serviceId) {
    return async (dispatch, getState) => {
        // const clientBillableAccountsPath = `clients/${clientId}/billable_accounts/sidebar_records`

        const response = await client.get(lastFiveBillsForServiceUrl(serviceId))
        return response.data
    }
}

// export function fetchBillableAccountServices(clientId, pagination = null, currentPath = null) {
//     return async (dispatch, getState) => {
//         const clientServicesPath = `clients/${clientId}/services/sidebar_records`

//         const response = await client.get(`${dynamicUrl(clientServicesPath)}`)
//         return response.data.services
//     }
// }

export function submitBillableService(values, id, clientId, billableAccountId) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(billableAccountServiceUrl(clientId, billableAccountId, id), { service: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(billableAccountServicesUrl(clientId, billableAccountId), { service: values })
            return response.data
        }
    }
}

export function submitSupplyService(values, id, clientId, supplyAccountId) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(supplyAccountServiceUrl(clientId, supplyAccountId, id), { service: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(supplyAccountServicesUrl(clientId, supplyAccountId), { service: values })
            return response.data
        }
    }
}

export function deleteService(serviceId) {
    return async (dispatch, getState) => {
        client
            .delete(`${serviceUrl(serviceId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })

                    dispatch(servicesSlice.actions.deleteService(serviceId))
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

export function fetchServiceOptionsForBillableAccount(clientId, utilityServiceId, accountId) {
    return async (dispatch, getState) => {
        const response = await client.get(serviceOptionsForBillableAccount(clientId, utilityServiceId, accountId))
        dispatch(setServiceOptionsForBillableAccount(response.data.services))
    }
}

export function fetchServiceOptionsForSupplyAccount(clientId, utilityServiceId) {
    return async (dispatch, getState) => {
        const response = await client.get(serviceOptionsForSupplyAccount(clientId, utilityServiceId))
        dispatch(setServiceOptionsForSupplyAccount(response.data.services))
    }
}

export function fetchEntryFieldSelectOptions() {
    return async (dispatch, getState) => {
        const response = await client.get(entryFieldSelectOptionsUrl)
        dispatch(setEntryFieldSelectOptions(response.data.entry_fields))
    }
}

// TODO: will need to scope this as well
// export function fetchServiceSelectOptions(searchTerm, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${serviceSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setServiceSelectOptions(response.data.services))
//         })
//     }
// }

// export function fetchServiceSelectOptionById(id, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${serviceSelectOptionByIdUrl}?service_id=${id}`).then((response) => {
//             dispatch(setServiceSelectOptions(response.data.service))
//         })
//     }
// }

// export function searchServices(query) {
//     return async (dispatch) => {
//         dispatch(setLoading(true))

//         client
//             .get(`${serviceSearchUrl}?q=${query}`)
//             .then(({ data }) => {
//                 dispatch(setServiceSearch(data))
//             })
//             .catch((error) => {
//                 console.warn(error)
//             })
//             .finally(() => {
//                 dispatch(setLoading(false))
//             })
//     }
// }

export const selectService = (state) => state.services.service
export const selectServices = (state) => state.services.services
export const selectTotal = (state) => state.services.total
export const selectPagination = (state) => state.services.pagination
export const selectLoading = (state) => state.services.loading
export const selectServiceSelectOptions = (state) => state.services.serviceSelectOptions
export const selectServiceSearch = (state) => state.services.serviceSearch
export const selectServiceOptionsForBillableAccount = (state) => state.services.serviceOptionsForBillableAccount
export const selectServiceOptionsForSupplyAccount = (state) => state.services.serviceOptionsForSupplyAccount
export const selectEntryFieldSelectOptions = (state) => state.services.entryFieldSelectOptions

export default servicesSlice.reducer
