import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../lib/client"
import { vendorsUrl, vendorUrl, vendorSelectOptionsUrl, dynamicUrl, vendorSearchUrl, vendorsCsvUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

export const VendorsSlice = createSlice({
    name: "vendors",
    initialState: {
        vendor: {},
        vendors: [],
        vendorSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        vendorSearch: [],
    },
    reducers: {
        setVendor: (state, action) => {
            state.vendor = action.payload
        },
        setVendors: (state, action) => {
            state.vendors = action.payload.vendors
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setVendorSelectOptions: (state, action) => {
            state.vendorSelectOptions = action.payload
        },
        setVendorSearch: (state, action) => {
            state.vendorSearch = action.payload
        },
    },
})

export const { setVendor, setVendors, setPagination, setLoading, setVendorSelectOptions, setVendorSearch } = VendorsSlice.actions

export function fetchVendor(id) {
    return async (dispatch, getState) => {
        client.get(vendorUrl(id)).then((response) => {
            dispatch(setVendor(response.data))
        })
    }
}

export function fetchVendors(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setVendors(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function searchVendors(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${vendorSearchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setVendorSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchClientVendors(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientVendorsPath = `clients/${clientId}/vendors/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientVendorsPath)}`)
        return response.data.vendors
    }
}

export function submitVendor(values) {
    return async (dispatch, getState) => {
        const id = getState().vendors.vendor.id
        if (id) {
            // patch - update
            const response = await client.patch(vendorUrl(id), { vendor: values })
            if (!response.data.success) {
                notification.error({
                    message: response.data.message,
                    duration: 0.8,
                })
            }
            return response.data
        } else {
            // post - create
            const response = await client.post(vendorsUrl, { vendor: values })
            if (!response.data.success) {
                notification.error({
                    message: response.data.message,
                    duration: 0.8,
                })
            }
            return response.data
        }
    }
}

export function fetchVendorSelectOptions(clientId, pagination = null, currentPath = null, searchTerm = null) {
    return async (dispatch, getState) => {
        client.get(`${vendorSelectOptionsUrl()}?search_term=${searchTerm}`).then((response) => {
            dispatch(setVendorSelectOptions(response.data.vendors))
        })
    }
}

export function deleteVendor(vendorId) {
    return async (dispatch, getState) => {
        client
            .delete(`${vendorUrl(vendorId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })
                window.location.reload();
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

export function filterVendors(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, vendorsUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setVendors(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(filters) {
    return async (dispatch, getState) => {
        client.get(`${vendorsCsvUrl}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectVendor = (state) => state.vendors.vendor
export const selectVendors = (state) => state.vendors.vendors
export const selectTotal = (state) => state.vendors.total
export const selectPagination = (state) => state.vendors.pagination
export const selectLoading = (state) => state.vendors.loading
export const selectVendorSelectOptions = (state) => state.vendors.vendorSelectOptions
export const selectVendorSearch = (state) => state.vendors.vendorSearch

export default VendorsSlice.reducer
