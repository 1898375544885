import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../../lib/client"
import { paymentMethodUrl, paymentMethodsUrl, dynamicUrl } from "../../../lib/endpoints"

export const PaymentMethodsSlice = createSlice({
    name: "payment_methods",
    initialState: {
        payment_method: {},
        payment_methods: [],
        paymentMethodsSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 20 },
        loading: false,
        paymentMethodsSearch: [],
    },
    reducers: {
        setPaymentMethod: (state, action) => {
            state.payment_method = action.payload
        },
        setPaymentMethods: (state, action) => {
            state.payment_methods = action.payload.payment_methods
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
})

export const { setPaymentMethod, setPaymentMethods, setPagination, setLoading, setPaymentMethodsSelectOptions, setPaymentMethodsSearch } = PaymentMethodsSlice.actions

export function fetchPaymentMethod(id) {
    return async (dispatch, getState) => {
        client.get(paymentMethodUrl(id)).then((response) => {
            dispatch(setPaymentMethod(response.data))
        })
    }
}

export function fetchPaymentMethods(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${paymentMethodsUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setPaymentMethods(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function submitPaymentMethod(values) {
    return async (dispatch, getState) => {
        const id = getState().payment_methods.payment_method.id
        if (id) {
            // patch - update
            const response = await client.patch(paymentMethodUrl(id), { payment_method: values })
            if (!response.data.success) {
                notification.error({
                    message: response.data.message,
                    duration: 0.8,
                })
            }
            return response.data
        } else {
            // post - create
            const response = await client.post(paymentMethodsUrl, { payment_method: values })
            if (!response.data.success) {
                notification.error({
                    message: response.data.message,
                    duration: 0.8,
                })
            }
            return response.data
        }
    }
}

export function deletePaymentMethod(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${paymentMethodUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })
                window.location.reload();
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

export const selectPaymentMethod = (state) => state.payment_methods.payment_method
export const selectPaymentMethods = (state) => state.payment_methods.payment_methods
export const selectTotal = (state) => state.payment_methods.total
export const selectPagination = (state) => state.payment_methods.pagination
export const selectLoading = (state) => state.payment_methods.loading
export const selectPaymentMethodsSelectOptions = (state) => state.payment_methods.paymentMethodsSelectOptions
export const selectVendorSearch = (state) => state.payment_methods.paymentMethodsSearch

export default PaymentMethodsSlice.reducer
