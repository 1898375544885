import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { selectScopedByClient } from "../../stores/appSlice"
import { fetchDivision, selectDivision } from "./divisionsSlice"

export default function DivisionNav({ activeTab, scopedByDivision = false }) {
    const dispatch = useDispatch()
    const { clientId, divisionId } = useParams()
    const division = useSelector(selectDivision)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the division we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (divisionId) {
            dispatch(fetchDivision(divisionId))
        }
    }, [dispatch, divisionId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (divisionId && scopedByDivision) {
                return `/clients/${clientId}/divisions/${divisionId}${newRoute}`
            } else {
                return `/clients/${clientId}/divisions${newRoute}`
            }
        } else if (divisionId) {
            return `/divisions/${divisionId}${newRoute}`
        }

        return `/divisions${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedByDivision ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Divisions</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Divisions
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>
                    <Link to={() => `/clients/${clientId}/divisions`}> Divisions > </Link>
                    {`${division.name}`}
                </h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Dashboard
                           </button>
                       </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/facilities")}>
                            <button type="link" className={`${activeTab === "facilities" ? "selected" : ""}`}>
                                Division's Facilities
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/documents")}>
                            <button type="link" className={`${activeTab === "documents" ? "selected" : ""}`}>
                                Documents
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Divisions</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Divisions Dashboard
                           </button>
                       </Link>
                    </li>
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                Division List
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
