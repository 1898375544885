import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../lib/client"
import { facilitiesUrl, facilityUrl, clientFacilitySelectOptionsUrl, dynamicUrl, facilitySearchUrl, facilitiesScopedByClientUrl, clientFacilitiesCsvUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

export const facilitiesSlice = createSlice({
    name: "facilities",
    initialState: {
        facility: {},
        facilities: [],
        facilitySearch: [],
        facilitySelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setFacility: (state, action) => {
            state.facility = action.payload
        },
        setFacilities: (state, action) => {
            state.facilities = action.payload.facilities
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setFacilitySearch: (state, action) => {
            state.facilitySearch = action.payload
        },
        setFacilitySelectOptions: (state, action) => {
            state.facilitySelectOptions = action.payload
        },
        deleteFacility: (state, action) => {
            state.facilities = state.facilities.filter((facility) => facility.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setFacility, setFacilities, setPagination, setLoading, setFacilitySelectOptions, setFacilitySearch } = facilitiesSlice.actions

export function fetchFacility(id) {
    return async (dispatch, getState) => {
        client.get(facilityUrl(id)).then((response) => {
            dispatch(setFacility(response.data))
        })
    }
}

export function fetchDivisionFacilities(ids,clientId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        // TODO: we can handle it when facility pagination works so lets just load all the related facilities
        client.get(`${facilitiesScopedByClientUrl(clientId)}?page=${1}&per=${1000}&division_id[]=${ids.join('&division_id[]=')}`).then((response) => {
            dispatch(setFacilities(response.data))
            dispatch(setLoading(false))
        })
    }
}
export function fetchFacilities(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setFacilities(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientFacilities(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientFacilitiesPath = `clients/${clientId}/facilities/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientFacilitiesPath)}`)
        return response.data.facilities
    }
}

export function submitFacility(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(facilityUrl(id), { facility: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(facilitiesUrl, { facility: values })
            return response.data
        }
    }
}

export function deleteFacility(facilityId) {
    return async (dispatch, getState) => {
        client
            .delete(`${facilityUrl(facilityId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                        duration: 0.8,
                    })

                    dispatch(facilitiesSlice.actions.deleteFacility(facilityId))
                } else {
                    notification.error({
                        message: data.message,
                        duration: 0.8,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
                console.error(e)
            })
    }
}

export function fetchClientFacilitySelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${clientFacilitySelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setFacilitySelectOptions(response.data.facilities))
        })
    }
}

export function searchFacilities(query, searchUrl = facilitySearchUrl) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${searchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setFacilitySearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterFacilities(query, { divisionId } = {}) {
    let filterUrl = createFilterUrl(query, facilitiesScopedByClientUrl(query.clientId))
    if (divisionId) {
        filterUrl += "&division_id=" + divisionId
    }

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setFacilities(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${clientFacilitiesCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectFacility = (state) => state.facilities.facility
export const selectFacilities = (state) => state.facilities.facilities
export const selectTotal = (state) => state.facilities.total
export const selectPagination = (state) => state.facilities.pagination
export const selectLoading = (state) => state.facilities.loading
export const selectFacilitySearch = (state) => state.facilities.facilitySearch
export const selectFacilitySelectOptions = (state) => state.facilities.facilitySelectOptions

export default facilitiesSlice.reducer
