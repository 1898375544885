import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { loginUrl, signOutUrl } from "../../lib/endpoints"
import { setClient, setToken, setUid } from "../../lib/storage"
import { notification } from "antd"

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        loading: false,
        loggedIn: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
    },
})

export const { setLoading, setLoggedIn } = loginSlice.actions

export function requestLogin(values) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .post(loginUrl, values)
            .then((response) => {
                // set local storage attributes that are required for future requests
                setToken(response.headers["access-token"])
                setClient(response.headers["client"])
                setUid(response.headers["uid"])

                dispatch(setLoggedIn(true))
            })
            .catch((errors) => {
                notification.error({
                    message: "Sign In Error",
                    description: errors.response?.data?.errors?.join(", "),
                    duration: 0.8,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function submitSignOut() {
    return async (dispatch, getState) => {
        try {
            const response = await client.delete(signOutUrl)
            await dispatch(setLoggedIn(false))
            return response?.data
        } catch (e) {
            notification.error({
                message: "Sign Out Error",
                description: e?.response?.statusText,
                duration: 0.8,
            })

            return
        }
    }
}

export const selectLoading = (state) => state.login.loading
export const selectLoggedIn = (state) => state.login.loggedIn

export default loginSlice.reducer
