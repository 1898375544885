import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom"
import moment from "moment"

import { selectScopedByClient } from "../../stores/appSlice"
import { fetchPaymentBatch, selectPaymentBatch } from "./paymentBatches/paymentBatchesSlice"
import { formatCurrency } from "../../lib/utilities"

export default function PaymentBatchNav({ activeTab, scopedByPaymentBatch = false }) {
    const dispatch = useDispatch()
    const { utility_company_name } = useLocation()
    const { clientId, paymentBatchId } = useParams()
    const { path } = useRouteMatch()
    const paymentBatch = useSelector(selectPaymentBatch)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the paymentBatch we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (paymentBatchId) {
            dispatch(fetchPaymentBatch(paymentBatchId))
        }
    }, [dispatch, paymentBatchId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (paymentBatchId && scopedByPaymentBatch) {
                return `/clients/${clientId}/payment_batches/${paymentBatchId}${newRoute}`
            } else {
                return `/clients/${clientId}/payment_batches${newRoute}`
            }
        } else if (paymentBatchId) {
            return `/payment_batches/${paymentBatchId}${newRoute}`
        }

        return `/payment_batches${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedByPaymentBatch ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Vendor Batches</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Vendor Batches
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>
                    <Link to={() => `/clients/${clientId}/payment_batches`}>Vendor Batches</Link>
                    <span>
                        {!!(activeTab === "utilityPayments") ? " > Vendor Payments" : ""}
                        {!!(activeTab === "bills") ? " > Bills" : ""}
                        {!!paymentBatch ? ` for Vendor Batch ${moment(paymentBatch.batch_date).format("L")}: ${formatCurrency(paymentBatch.batch_total)}` : ""}
                    </span>
                </h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/vendor_payments")}>
                            <button type="link" className={`${activeTab === "utilityPayments" ? "selected" : ""}`}>
                                Vendor Payments
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/bills")}>
                            <button type="link" className={`${activeTab === "bills" ? "selected" : ""}`}>
                                Bills
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>
                    <Link to={() => `/clients/${clientId}/payment_batches`}>Vendor Batches</Link>
                    {!!(path === "/clients/:clientId/payment_batches/:paymentBatchId") ? ` > ${paymentBatch?.batch_group?.name} - ${paymentBatch.batch_code}` : ""}
                    {!!(path === "/clients/:clientId/payment_batches/:paymentBatchId/vendor_payments") ? " > Vendor Payments" : ""}
                    {!!utility_company_name ? ` > ${utility_company_name} Bills` : ""}
                </h2>
                <ul className="header-tab-nav" role="navigation">
                    {/* Uknown if we need dashboard */}
                    {/* <li>
                        <Link to={() => formatRoute("/dashboard")}>
                            <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                                Vendor Batches Dashboard
                            </button>
                        </Link>
                    </li> */}
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Vendor Batches
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
