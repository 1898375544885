import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Button, Tooltip, Skeleton, Checkbox, notification } from "antd"
import { DownloadOutlined, EditOutlined, SyncOutlined, CloudUploadOutlined } from "@ant-design/icons"
import moment from "moment"

import { fetchAccountingBatchBills, fetchAccountingBatchNewBills, selectAccountingBatch, selectBills, submitAccountingBatch, generateCsv, selectLoading } from "../accountingBatchesSlice"
import WarningChip from "../../../bills/components/subcomponents/WarningChip"
import BillAccountLink from "../../../../components/utilityComponents/linkGenerators/BillAccountLink"
import BillVendorPaymentLink from "../../../../components/utilityComponents/linkGenerators/BillVendorPaymentLink"
import ColumnHeaderTitle from "../../../../components/utilityComponents/table/ColumnHeaderTitle"

import { requestNotification } from "../../../../lib/notifications"
import {formatCurrency} from "../../../../lib/utilities";

export default function BillTable({ scopedByClient }) {
    const { url } = useRouteMatch()
    const { clientId, accountingBatchId } = useParams()
    const dispatch = useDispatch()
    const bills = useSelector(selectBills)
    const accountingBatch = useSelector(selectAccountingBatch)
    const [loaded, setLoaded] = useState(false)
    const [checkedBills, setCheckedBills] = useState([])
    const loading = useSelector(selectLoading)

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            dispatch(fetchAccountingBatchBills(clientId, accountingBatchId))
        }
    }, [loaded, url, dispatch]) // eslint-disable-line

    useEffect(() => {
        setCheckedBills([])
        const newChecked = []
        // old logic
        // bills.forEach((bill) => (bill.accounting_batch_id === parseInt(accountingBatchId) ? newChecked.push(bill.id) : null))
        bills.forEach((bill) => newChecked.push(bill.id))
        setCheckedBills(newChecked)
    }, [bills]) // eslint-disable-line

    const fetchNewBills = async () => {
        const response = await dispatch(fetchAccountingBatchNewBills(clientId, accountingBatchId))

        if (response.success) {
            notification.open({
                message: `Found ${response.new_bills} new bill(s)`,
                description: `Total: ${formatCurrency(response.total)}`,
                duration: 0.8,
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was a problem fetching new bills. Please try again.",
                duration: 0.8,
            })
        }
    }

    const updateChecked = (e, record, accountingBatchId) => {
        if (e.target.checked) {
            setCheckedBills(checkedBills.concat(record))
        } else {
            setCheckedBills(checkedBills.filter((bill) => bill !== record))
        }
    }

    const submitBills = async () => {
        // "false" param is to not try and format the date since it doesn't exist for this submission
        const response = await dispatch(submitAccountingBatch({ bill_ids: checkedBills }, accountingBatchId, clientId, false))

        requestNotification(response)
    }

    const billsCsv = async () => {
        notification.open({
            message: "Client Batch bills export started",
            duration: 0.8,
        })

        dispatch(generateCsv(accountingBatchId))
    }

    const renderColumnHeaderTitle = (title, row = null, altTitle = null) => {
        return <ColumnHeaderTitle row={row} title={title} altTitle={altTitle} />
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Bills</h2>
                            <div className="badge">{bills.length}</div>
                        </div>

                        <div className="actions">
                            <div className="table-action-links">
                                {!accountingBatch.has_been_closed && !accountingBatch.invoiced && (
                                    <a href="javascript:;" onClick={() => fetchNewBills()}>
                                        <SyncOutlined spin={false} />
                                        Check for New Bills
                                    </a>
                                )}

                                <Button type="link" onClick={() => billsCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table pagination={false} dataSource={bills} rowKey={(r) => r.id}>
                            {/* <Table.Column
                                title=" "
                                key="flags"
                                dataIndex="flags"
                                render={(flags) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} />}
                                width={50}
                            /> */}
                            <Table.Column
                                title={<Checkbox onChange={(e) => (e.target.checked ? setCheckedBills(bills.map((bill) => bill.id)) : setCheckedBills([]))} />}
                                key="in"
                                dataIndex="in"
                                render={(text, record) => (
                                    <Checkbox
                                        defaultChecked={true}
                                        checked={checkedBills.includes(record.id)}
                                        onChange={(e) => updateChecked(e, record.id, record.accountingBatchId)}
                                        disabled={accountingBatch.closed}
                                    />
                                )}
                            />
                            <Table.Column title="Division" key="division_name" dataIndex="division_name" />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("vendor_payment_full_name", row, "vendor payment")}
                                key="vendor_payment_full_name"
                                dataIndex="vendor_payment_full_name"
                                render={(text, record) => (
                                    record.accounting_batch_id ? 
                                    <BillVendorPaymentLink
                                        paymentBatchId={record.paymentBatchId}
                                        utilityPaymentId={record.vendor_payment_id}
                                        utilityPaymentName={record.vendor_payment_full_name}
                                        clientId={record.client_id}
                                    />
                                    : ''
                                )}
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => a.vendor_payment_full_name?.localeCompare(b.vendor_payment_full_name),
                                }}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("account_number", row)}
                                key="account_number"
                                dataIndex="account_number"
                                render={(text, record) => (
                                    <BillAccountLink accountType={record.account_type} accountId={record.account_id} accountName={record.account_name_with_number} clientId={record.client_id} />
                                )}
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => a.account_name?.localeCompare(b.account_name),
                                }}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("date", row, "bill date")}
                                key="date"
                                dataIndex="date"
                                render={(text, record) => moment(record.date).format("MM/DD/YYYY")}
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => moment(a.date) - moment(b.date),
                                }}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("due_date", row)}
                                key="due_date"
                                dataIndex="due_date"
                                render={(text, record) => moment(record.due_date).format("MM/DD/YYYY")}
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => moment(a.due_date) - moment(b.due_date),
                                }}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("balance_forward", row)}
                                key="balance_forward"
                                dataIndex="balance_forward"
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => a.balance_forward - b.balance_forward,
                                }}
                                align="right"
                                render={(text, record, index) => formatCurrency(text)}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("current_charges", row)}
                                key="current_charges"
                                dataIndex="current_charges"
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => a.current_charges - b.current_charges,
                                }}
                                align="right"
                                render={(text, record, index) => formatCurrency(text)}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("amount_due", row)}
                                key="amount_due"
                                dataIndex="amount_due"
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => a.amount_due - b.amount_due,
                                }}
                                align="right"
                                render={(text, record, index) => formatCurrency(text)}
                            />
                            <Table.Column
                                title={(row) => renderColumnHeaderTitle("amount_paid", row)}
                                key="amount_paid"
                                dataIndex="amount_paid"
                                showSorterTooltip={false}
                                sorter={{
                                    compare: (a, b) => a.amount_paid - b.amount_paid,
                                }}
                                align="right"
                                render={(text, record, index) => text ? formatCurrency(text) : ''}
                            />
                            <Table.Column
                                title="Copy"
                                key="copy"
                                render={(text, record) => (
                                    <>
                                        {record.file_url && (
                                            <Button type="primary" size="small" onClick={() => window.open(record.file_url, "_blank")}>
                                                <DownloadOutlined />
                                            </Button>
                                        )}
                                    </>
                                )}
                            />
                            <Table.Column
                                title="Status"
                                key="flags"
                                dataIndex="flags"
                                render={(flags, record) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} childFlags={record.any_child_flags} />}
                                width={50}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <div className="row-actions" style={{ display: "inline-block" }}>
                                        <Link to={`/clients/${clientId}/bills/${record.id}/edit`} target="_blank">
                                            <Tooltip title="Edit">
                                                <EditOutlined />
                                            </Tooltip>
                                        </Link>
                                    </div>
                                )}
                            />
                        </Table>

                        <br />

                        <Button type="primary" icon={<CloudUploadOutlined />} onClick={submitBills}>
                            Update Batch
                        </Button>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
