import React from 'react'
import { Form } from "antd"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from "lodash"
import PaginatedSelect from '../../../forms/components/PaginatedSelect'

import {
    fetchBillableAccounts,
    selectBillableAccounts,
    selectPagination,
    selectTotal,
    selectBillableAccountSearch,
    searchBillableAccounts
} from '../../../billableAccounts/billableAccountsSlice.js'

export default function ReportBillableAccounts() {
    const { clientId } = useParams()

    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)

    const handleSearch = debounce((value) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            dispatch(searchBillableAccounts(value, `clients/${clientId}/billable_accounts/search`))
        }
    }, 1000)

    return (
        <div>
            <Form.List name="form_data">
                {
                    fields => (
                        <Form.Item label="Billable Account" name="billable_account_ids">
                            <PaginatedSelect
                                mode='multiple'
                                placeholder='Search billable accounts...'
                                onSearch={handleSearch}
                                paginationSelector={selectBillableAccounts}
                                paginationTotalSelector={selectTotal}
                                loadPaginatedData={(p) => fetchBillableAccounts(p, `clients/${clientId}/billable_accounts`)}
                                searchSelector={selectBillableAccountSearch}
                                renderOptionValue={(o) => o.account_name_with_number}
                            />
                        </Form.Item>
                    )
                }
            </Form.List>
        </div>
    )
}
