import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Tooltip, Skeleton, notification, Button, Dropdown, Menu } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, SearchOutlined, FilterOutlined, DownOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchBillableAccounts, selectBillableAccounts, deleteBillableAccount, selectTotal, selectPagination, selectLoading, filterBillableAccounts, generateCsv } from "../billableAccountsSlice"
import { fetchSubaccounts, selectSubaccounts } from "../../groupAccounts/groupAccountsSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import ColumnToggleFilter from "../../../components/utilityComponents/table/ColumnToggleFilter"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnDateFilter from "../../../components/utilityComponents/table/ColumnDateFilter"
import moment from "moment"
import { filterSupplyAccounts } from "../../supplyAccounts/supplyAccountsSlice"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"

import Role from "../../userRoles/Role"

export default function BillableAccountTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    const { clientId, groupAccountId, facilityId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const billableAccounts = useSelector(selectBillableAccounts)
    const subaccounts = useSelector(selectSubaccounts)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialBillableAccounts()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialBillableAccounts = () => {
        if (!!groupAccountId) {
            dispatch(fetchSubaccounts(pagination, nodePath.join(url, "")))
        } else {
            dispatch(fetchBillableAccounts(pagination, nodePath.join(url, "")))
        }
    }
    
    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteBillableAccount, id);
    };

    const handleFilterBillableAccounts = (newPagination = null, newSort = null) => {
        let paggy = !!newPagination ? newPagination : pagination
        return dispatch(
            filterBillableAccounts(
                {
                    clientId: clientId,
                    filters: filters,
                    pagination: !!newPagination ? newPagination : pagination,
                    sort: !!newSort ? newSort : sort,
                },
                { facilityId: facilityId }
            )
        )
    }

    const handleSortBillableAccountsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterBillableAccounts(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            if (groupAccountId) {
                dispatch(fetchSubaccounts(newPagination, nodePath.join(url, "")))
            } else {
                dispatch(fetchBillableAccounts(newPagination, nodePath.join(url, "")))
            }
        } else {
            handleFilterBillableAccounts(newPagination)
        }
    }

    const billableAccountsCsv = () => {
        notification.open({
            message: "Billable accounts export started",
            duration: 0.8,
        })

        dispatch(generateCsv(clientId, filters))
    }
    /////*** event handler methods end ***/////

    /////*** render methods start ***/////
    const renderColumnHeaderTitle = (title, altTitle = null) => {
        return <ColumnHeaderTitle title={title} altTitle={altTitle} handleSortChange={handleSortBillableAccountsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialBillableAccounts()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const renderTableActions = () => {
        if (groupAccountId) {
            return (
                <div className="actions">
                    <div className="table-action-links">
                        {renderResetFilters()}

                        <Link to={`/clients/${scopedByClient}/group_accounts/${groupAccountId}/edit`}>
                            <PlusOutlined />
                            Manage
                        </Link>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="actions">
                    <div className="table-action-links">
                        {renderResetFilters()}

                        <Role action="create" model="billable_account">
                            <Link to={scopedByClient ? `/clients/${scopedByClient}/billable_accounts/new` : `/billable_accounts/new`}>
                                <PlusOutlined />
                                Add New
                            </Link>
                        </Role>

                        <Link to={`${url}/import`}>
                            <PlusOutlined />
                            Import
                        </Link>

                        <a href="javascript:;" onClick={() => billableAccountsCsv()}>
                            <DownloadOutlined />
                            Export
                        </a>
                    </div>
                </div>
            )
        }
    }
    /////*** render methods end ***/////

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>{groupAccountId ? "Subaccounts" : "Billable Accounts"}</h2>
                            <div className="badge">{total}</div>
                        </div>

                        {renderTableActions()}
                    </div>

                    <Skeleton active loading={loading}>
                        <Table
                            dataSource={groupAccountId ? subaccounts : billableAccounts}
                            pagination={{ ...pagination, showSizeChanger: true, total: total, pageSizeOptions: [10, 20, 50, 100, 200] }}
                            onChange={handleTableChange}
                            rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("facility_name", "facility")}
                                key="facility_id"
                                dataIndex="facility"
                                render={(facility) => facility?.name}
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"facility_name"}
                                        filterType={"facility_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("facility_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("vendor_name", "vendor")}
                                key="vendor_id"
                                dataIndex="vendor_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"vendor_name"}
                                        filterType={"vendor_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("vendor_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("account_number")}
                                key="account_number"
                                dataIndex="account_number"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"account_number"}
                                        filterType={"account_number_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("account_number_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column
                                title="Services"
                                key="services"
                                dataIndex="service_names"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"services_names"}
                                        filterType={"services_meter_number_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("services_meter_number_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("active")}
                                key="active"
                                dataIndex="active"
                                render={(active) => (active ? "Active" : "Inactive")}
                                filterDropdown={({ visible }) => (
                                    <ColumnToggleFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"active"}
                                        filterType={"active_eq"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("active_eq")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("bills_date","Last Bill Date")}
                                // title={"Last Bill Date"}
                                key="last_bill_date"
                                render={(record) => (record.last_bills_date ? moment(record.last_bills_date).format("L") : "")}
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"bills_date"}
                                        filterType={"bills_date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("bills_date_lteq" || "bills_date_gteq")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("created_at")}
                                key="created_at"
                                dataIndex="created_at"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialBillableAccounts}
                                        filterAction={filterBillableAccounts}
                                        filterColumn={"created_at"}
                                        filterType={"created_at"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        facilityId={facilityId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("created_at_lteq" || "created_at_gteq")}
                                render={(text, record, index) => moment(record.created_at).format("L")}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="billable_account">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={scopedByClient ? `/clients/${scopedByClient}/billable_accounts/${record.id}/edit` : `/billable_accounts/${record.id}/edit`}>
                                                                Edit Account
                                                            </Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Menu.Item key="2" icon={<EyeOutlined />}>
                                                        <Link to={`/clients/${scopedByClient}/billable_accounts/${record.id}/dashboard`}>View</Link>
                                                    </Menu.Item>

                                                    <Role action="destroy" model="billable_account">
                                                        <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                            Delete Account
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
