import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Row, Col, notification, Upload } from "antd"
import { LeftOutlined, FileAddOutlined, DownloadOutlined } from "@ant-design/icons"
import nodePath from "path"

import client from "../../lib/client"
import { selectUid } from "../../stores/appSlice"
import { billsImportUrl, ledgerAccountsImportTemplateUrl, ledgerAccountsImportUrl, ledgerFieldsImportUrl, vendorsImportUrl, divisionImportUrl, facilityImportUrl, billableAccountImportUrl, supplyAccountImportUrl, ledgerFieldUrl } from "../../lib/endpoints"
import divisionsTemplate from "../../assets/import_templates/divisions_import_template.csv"
import facilitiesTemplate from "../../assets/import_templates/facilities_import_template.csv"
import accountsImportTemplate from "../../assets/import_templates/accounts_import_template.csv"
import supplyAccountsImportTemplate from "../../assets/import_templates/supply_accounts_import_template.csv"
import vendorsTemplate from "../../assets/import_templates/vendors_import_template.csv"
import ledgerFieldsTemplate from "../../assets/import_templates/ledger_fields_import_template.csv"
import billsTemplate from "../../assets/import_templates/bills_import_template.csv"

export default function ImportForm({ dataType }) {
    const { url } = useRouteMatch()
    const { clientId } = useParams()
    const uid = useSelector(selectUid)
    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fileSelect = (file) => {
        setFile(file)
        return false
    }

    const fileRemove = () => {
        setFile(null)
    }

    const openNotification = () => {
        notification.open({
            message: "Starting Import Process",
            description: `Starting ${dataType} import process`,
            duration: 0.8,
        })
    }

    const getEndpointForDataType = (dataType) => {
        switch (dataType) {
            case "bills":
                return `${billsImportUrl(clientId)}?connection_id=${uid}`
            case "vendor":
                return `${vendorsImportUrl}?connection_id=${uid}`
            case "ledger_account":
                return `${ledgerAccountsImportUrl(clientId)}?connection_id=${uid}` 
            case "ledger_field":
                return `${ledgerFieldsImportUrl(clientId)}?connection_id=${uid}`    
            case "division":
                return `${divisionImportUrl(clientId)}?connection_id=${uid}`
            case "facility":
                return `${facilityImportUrl(clientId)}?connection_id=${uid}`
            case "billableAccount":
                return `${billableAccountImportUrl(clientId)}?connection_id=${uid}`
            case "supplyAccount":
                return `${supplyAccountImportUrl(clientId)}?connection_id=${uid}`
            default:
                return divisionImportUrl()
        }
    }

    const getImportTemplate = (dataType) => {
        switch (dataType) {
            case "ledger_account":
               return (window.location.href = ledgerAccountsImportTemplateUrl(clientId))
            case "bills":
                window.location.href = billsTemplate
                break   
            case "ledger_field":
                window.location.href = ledgerFieldsTemplate
                break
            case "vendor":
                window.location.href = vendorsTemplate
                break
            case "facility":
                window.location.href = facilitiesTemplate
                break
            case "division":
                window.location.href = divisionsTemplate
                break
            case "billableAccount":
                return (window.location.href = accountsImportTemplate)
            case "supplyAccount":
                return (window.location.href = supplyAccountsImportTemplate)
            default:
                return divisionsTemplate
        }
    }

    const getFormattedDataType = (dataType) => {
        switch (dataType) {
            case "ledger_account":
                return  "Ledger Accounts"
            case "bills":
                return  "Bills"    
            case "ledger_field":
                return  "Ledger Fields"
            case "vendor":
                return "Vendors"
            case "division":
                return "Divisions"
            case "facility":
                return "Facilities"
            case "billableAccount":
                return "Billable Accounts"
            case "supplyAccount":
                return "Supply Accounts"
            default:
                return "Divisions"
        }
    }

    const startImport = async (options) => {
        openNotification()
        setUploading(true)
        const fmData = new FormData()
        fmData.append("file", file)

        try {
            const response = await client({
                method: "post",
                url: getEndpointForDataType(dataType),
                data: fmData,
                config: {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            })
        } catch (err) {
            console.log("Error: ", err)
        }
        setUploading(false)
    }

    return (
        <div id="user-form-container">
            <Link to={nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>

            <div className="card">
                <div className="card-body">
                    <div>
                        <Row gutter={24}>
                            <Col span={24}>
                                <h3>Import {getFormattedDataType(dataType)}</h3>
                                <p>Import larger quantities of data all at once using CSV Import.</p>
                                <p>
                                    <b>
                                        The required format of the file data is VERY specific. It is HIGHLY recommended you download the template file below to ensure your data is properly imported.
                                    </b>
                                </p>
                                <Button icon={<DownloadOutlined />} onClick={() => getImportTemplate(dataType)} style={{ marginBottom: "20px" }}>
                                    Download Template File
                                </Button>
                            </Col>
                        </Row>

                        <hr />

                        <Row gutter={24}>
                            <Col span={24}>
                                <Upload maxCount={1} accept="csv" beforeUpload={fileSelect} onRemove={fileRemove}>
                                    <Button icon={<FileAddOutlined />}>Select Import File</Button>
                                </Upload>
                            </Col>
                        </Row>

                        <br />

                        <Row gutter={24}>
                            <Col span={24}>
                                <Button type="primary" onClick={() => startImport()} loading={uploading}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}
