import { createSlice } from "@reduxjs/toolkit"
import { calculateDateRangesByType } from "../../lib/dashboards"
import client from "../../lib/client"
import { clientUrl, clientUtilityServicesUrl } from "../../lib/endpoints"
import { notification } from "antd"

export const dashboardsSlice = createSlice({
    name: "dashboards",
    initialState: {
        service: "",
        compareToLastYear: false,
        dateRangeType: "last_12_months",
        loading: false,
        reportData: {},
        graphData: {},
        graphDataType: "cost",
        graphSummaryMode: false,
        graphSummaryData: {},
        services: [],
        ...calculateDateRangesByType("last_12_months", false),
    },
    reducers: {
        setService: (state, action) => {
            state.service = action.payload
        },
        setCompareToLastYear: (state, action) => {
            return {
                ...state,
                ...calculateDateRangesByType(state.dateRangeType, action.payload),
                compareToLastYear: action.payload,
            }
        },
        setDateRangeType: (state, action) => {
            return {
                ...state,
                ...calculateDateRangesByType(action.payload, state.compareToLastYear),
                dateRangeType: action.payload,
            }
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setReportData: (state, action) => {
            state.reportData = action.payload?.dashboard
            state.graphData = action.payload?.graphs
            state.graphSummaryData = action.payload?.summary
        },
        setGraphDataType: (state, action) => {
            state.graphDataType = action.payload
        },
        setGraphSummaryMode: (state, action) => {
            state.graphSummaryMode = action.payload
        },
        setGraphSummaryData: (state, action) => {
            state.graphSummaryData = action.payload
        },
        setServices: (state, action) => {
            state.services = action.payload
        },
    },
})

export const { setService, setCompareToLastYear, setDateRangeType, setLoading, setReportData, setGraphDataType, setGraphSummaryMode, setGraphSummaryData, setServices } = dashboardsSlice.actions

export const selectService = (state) => state.dashboards.service
export const selectCompareToLastYear = (state) => state.dashboards.compareToLastYear
export const selectDateRangeType = (state) => state.dashboards.dateRangeType
export const selectLoading = (state) => state.dashboards.loading
export const selectReportData = (state) => state.dashboards.reportData
export const selectGraphData = (state) => state.dashboards.graphData
export const selectGraphDataType = (state) => state.dashboards.graphDataType
export const selectGraphSummaryMode = (state) => state.dashboards.graphSummaryMode
export const selectGraphSummaryData = (state) => state.dashboards.graphSummaryData
export const selectCostGraphData = (state) => state.dashboards.graphData?.cost_and_usage
export const selectServices = (state) => state.dashboards.services
export const selectDateRangeValues = (state) => {
    return {
        dateRangeFrom: state.dashboards.dateRangeFrom,
        dateRangeTo: state.dashboards.dateRangeTo,
        lastYearDateRangeFrom: state.dashboards.lastYearDateRangeFrom,
        lastYearDateRangeTo: state.dashboards.lastYearDateRangeTo,
    }
}

export function fetchServices(clientId) {
    return async (dispatch, getState) => {
        client
            .get(clientUtilityServicesUrl(clientId))
            .then(({ data }) => {
                dispatch(setServices(data))
            })
            .catch((e) => {
                console.error(e)

                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
            })
    }
}

export function submitQuery(service, scopeType, scopeId, clientId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const state = getState().dashboards
        const requestService = service || state.service
        const compareToLastYear = state.compareToLastYear
        const dateRangeType = state.dateRangeType
        const graphDataType = state.graphDataType
        const summary = state.graphSummaryMode

        const params = [
            `?scope_type=${scopeType || ""}`,
            `&scope_id=${scopeId || ""}`,
            `&client_id=${clientId || ""}`,
            `&service=${requestService || ""}`,
            `&compare_to_last_year=${compareToLastYear}`,
            `&date_range_type=${dateRangeType}`,
            `&graph_data_type=${graphDataType}`,
            `&summary=${summary}`,
        ]

        client
            .get(`${clientUrl(clientId)}/dashboard${params.join("")}`)
            .then(({ data }) => {
                if (data.error) {
                    notification.error({
                        message: "Response error",
                        description: data.message,
                        duration: 0.8,
                    })
                    return
                }

                dispatch(setReportData(data))
            })
            .catch((e) => {
                console.error(e)

                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                    duration: 0.8,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export default dashboardsSlice.reducer
