import { LeftOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, notification, Row, Skeleton } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { fetchProfile, selectLoading, selectProfile, submitProfile } from "./profilesSlice"

export default function Profile() {
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const [init, setInit] = useState(false)

    const loading = useSelector(selectLoading)
    const profile = useSelector(selectProfile)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchProfile())
    }, [init])

    useEffect(() => {
        if (!profile) {
            return
        }

        form.resetFields()
    }, [profile])

    const submit = (values) => {
        if (!validPassword(values)) {
            return
        }

        dispatch(submitProfile(values))
    }

    const validPassword = (values) => {
        // check if either password field is populated
        if (values.password || values.password_confirmation) {
            // check if either the password or confirmation is missing
            if (!(values.password && values.password_confirmation)) {
                notification.error({
                    message: "Password error",
                    description: "You must supply both a password and a password confirmation when resetting your password.",
                    duration: 0.8,
                })
                return false
            }

            // check if the two don't match
            if (values.password !== values.password_confirmation) {
                notification.error({
                    message: "Password error",
                    description: "Password and password confirmation must match.",
                    duration: 0.8,
                })
                return false
            }
        }

        // password should be OK to send
        return true
    }

    return (
        <div id="user-form-container">
            <Link to={"/"}>
                <LeftOutlined />
                Go Back
            </Link>

            <Form form={form} onFinish={submit} initialValues={profile}>
                <div className="card">
                    <div className="card-body">
                        <Skeleton active={true} loading={loading}>
                            <div>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="First Name" name="first_name" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Last Name" name="last_name" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item label="Title" name="title" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Password" name="password" rules={[{ required: false }]}>
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label="Password Confirmation" name="password_confirmation" rules={[{ required: false }]}>
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" disabled={loading}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </Form>
        </div>
    )
}
